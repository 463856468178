.product-download {

  .product-download-intro-line {
    //min-height: 304px;

    border-top: 1px solid rgba(0, 0, 0, 0.18);
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  }

  .product-download-latest-list-bg {
    //background: rgba(0,0,0,0.16);

  }
  .product-download-latest-list {

    .list-group {

      border-left: 1px solid rgba(0, 0, 0, 0.36);
      border-right: 1px solid rgba(0, 0, 0, 0.36);
      border-bottom: 1px solid rgba(0, 0, 0, 0.36);

    }

    .btn-default {
      border-bottom: 1px solid rgba(0, 0, 0, 0.18);
      border-left: 0px;
      border-right: 0px solid rgba(0, 0, 0, 0.36);
      border-top: 0px solid rgba(0, 0, 0, 0.36);
    }

    .btn-default:last-child {
      border-bottom: 0px solid rgba(0, 0, 0, 0.36);
    }

  }

  .product-download-img {
    min-height:180px;
  }

}




.list-group-title {
  @extend h4;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0.55rem 1.25rem;
  margin-bottom: -1px;

  background: rgba(0, 0, 0,1);
  border-radius: 0;
  color: $white;
}

