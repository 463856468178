/*------------------------------------*\
    #BUTTONS
\*------------------------------------*/

/**
 * Button
 */

// Button settings
.btn {
	min-width:12.5em;
	min-height:2.5em;
}

// Default button
.btn-default,
.btn-default
{


	border: 1px solid rgba(0,0,0,0.20);
	color:#333;
	background: url("../images/icon-btn-arrow-right.svg") right 14px center no-repeat;

	&:hover, &:focus {
		background: rgba(0,0,0,0.80) url("../images/icon-btn-arrow-right.svg") right 10px center no-repeat;
		color:#fff;
		border-radius:8px;
	}


}


// Button text positioning

.btn-center {
	text-align: center;
}

.btn-left {
	text-align: left;
}


// Primary button

.btn-primary {

	background: $brand-orange url("../images/icon-btn-arrow-right-white.svg") right 14px center no-repeat;
	border-radius:8px;

	&:hover, &:focus {
		background: $brand-orange-extra-dark url("../images/icon-btn-arrow-right-white.svg") right 10px center no-repeat;
		border-color:$brand-orange-dark;
		color:#fff;
		border-radius:8px;
	}

}

// Text button

.btn-link {
	padding-right: 2.2em;

	background: $white url("../images/icon-btn-arrow-right.svg") right 14px center no-repeat;

	&:hover, &:focus {
		background: $white url("../images/icon-btn-arrow-right.svg") right 10px center no-repeat;
		border-color:$white;
		color:#000;
		border-radius:8px;
	}
}
