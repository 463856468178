

.list-group {

  .list-group-item {
    //border-left:1px solid rgba(0,0,0,0.36);
    //border-right:1px solid rgba(0,0,0,0.36);
    border-top:0px solid #000;
    border-left:0px solid #000;
    border-right:0px solid #000;
    border-bottom:1px solid rgba(0,0,0,.08);

  }

  //.list-group-item:first-child,
  //.list-group-item:last-child {
  //  border-radius: $btn-border-radius;
  //}

  .list-group-item:last-child{
    border-top:0px solid #000;
    border-left:0px solid #000;
    border-right:0px solid #000;
    border-bottom:0px solid rgba(0,0,0,0.36);
  }
  a.list-group-title {
    @extend h4;
    padding: 0.55em 1.25em;
    background: #fff;
    color: #000;
    border-left:0px solid #000;
    border-right:0px solid #000;
    border-bottom:1px solid rgba(0,0,0,0.4);
  }

  a.active {

      background: #000 url("../../../images/icon-btn-arrow-right.svg") right 10px center no-repeat;

      color:#fff;
      //border:1px solid #000;

  }
}