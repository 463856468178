//	Listviews

.mm-listview,
.mm-listview > li
{
	list-style: none;
	display: block;
	padding: 0;
	margin: 0;
}
.mm-listview
{
	font: inherit;
	font-size: $mm_fontSize;
	line-height: $mm_btnSize - ( $mm_padding * 2 );

	a,
	a:hover
	{
		text-decoration: none;
	}

	> li
	{
		position: relative;

		&,
		&:after,
		.mm-next,
		.mm-next:before
		{
			border-color: inherit;
		}

		> a,
		> span
		{
			@include mm_ellipsis;

			color: inherit;
			display: block;
			padding: $mm_listitemPadding $mm_listitemPadding $mm_listitemPadding $mm_listitemIndent;
			margin: 0;
		}
		
		&:not(.mm-divider)
		{
			@include mm_border_bottom;

			&:after
			{
				left: $mm_listitemIndent;
			}
		}
	}

	// subopen
	.mm-next
	{
		@include mm_border_left;

		background: rgba( 3, 2, 1, 0 );
		width: $mm_subopenWidth;
		padding: 0;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 2;

		+ a,
		+ span
		{
			margin-right: $mm_subopenWidth;
		}

		&.mm-fullsubopen
		{
			width: 100%;
			
			&:before
			{
				border-left: none;
			}
			
			+ a,
			+ span
			{
				padding-right: $mm_subopenWidth;
				margin-right: 0;
			}
		}
	}
}
.mm-panels > .mm-panel 
{
	> .mm-listview
	{
		margin: $mm_panelPadding ( -$mm_panelPadding );
	}
	> .mm-listview:first-child,
	> .mm-navbar + .mm-listview
	{
		margin-top: -$mm_panelPadding;
	}
}