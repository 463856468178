

.top-nav {

    background:#000;
    top: 1px solid $nav-border-color;
    border-bottom: 1px solid $nav-border-color;

    li.nav-item {

      a {
        @extend .nav-link;

        font-size: 0.875em;
        color: rgba(255, 255, 255, 0.38);



        &:hover, &:focus {
          color: $white;
          background: none;
        }
      }



    }

  .phone  {
    border-left: 1px solid $nav-border-color;
    color:$white;
    font-weight: $font-weight-bold;
    line-height:36px;

    a,a:link {
      color:$white;
      font-weight: $font-weight-bold;
      vertical-align: middle;

      &:hover, &:focus {
        color:$white;
        background:none;
      }

    }
  }


  @include media-breakpoint-down(md) {
    .phone {
      border-left: 0px solid $nav-border-color;
      border-right: 1px solid $nav-border-color;

      line-height: 58px;
    }
  }

  @include media-breakpoint-down(sm) {


    .phone-mobile-menu li:not(:last-child) {
      border-left: 0px solid $nav-border-color;
      border-right: 1px solid $nav-border-color;

      min-height:52px;

      position: relative;

      padding-left:0;
      padding-right:0;

      color:$white;
      font-size:1.6rem;
      font-weight: $font-weight-bold;
      line-height:36px;

      text-align: center;
      vertical-align: middle;

      a,a:link {
        color:$white;
        font-weight: $font-weight-bold;
        vertical-align: middle;

        &:hover, &:focus {
          color:$white;
          background:none;
        }

      }


    }


    .mobile-menu .hamburger {

      margin-top:10px;
      padding:0;
      padding-left: 19%;
    }
  }

}

.language-selector {

  li {
    border-left: 1px solid $nav-border-color;
    min-width: 42px;
  }
}
@include media-breakpoint-down(md) {
span.phone-number {
  font-size:1rem;
  }
}

@include media-breakpoint-down(md) {
  .language-selector {

    //min-height:62px;

    li {
      border-left: 0px solid $nav-border-color;
      border-right: 1px solid $nav-border-color;
      min-width: 62px;
      line-height: 42px;



    }
  }
}

