.block-subcategory-productlist {


  .intro-line {
    //min-height: 304px;
    border-top: 1px solid rgba(0, 0, 0, 0.18);
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  }

  .product-list-bg {
    //background: rgba(0,0,0,0.16);
  }

  .product-list {

    .list-group {
      flex-direction: inherit;
      border-left: 1px solid rgba(0, 0, 0, 0.36);
      border-right: 0px solid rgba(0, 0, 0, 0.36);
      border-bottom: 1px solid rgba(0, 0, 0, 0.36);

        .btn-default {
          border-top: 1px solid rgba(0, 0, 0, 0.18);
          border-left: 0px;
          border-right: 1px solid rgba(0, 0, 0, 0.36);
        }

        .list-group-item:first-child,
        .list-group-item:nth-child(2) {
          border-top: 0px solid rgba(0, 0, 0, 0.36);
        }

        @include media-breakpoint-down(xs) {


          .list-group-item:nth-child(2) {
            border-top: 1px solid rgba(0, 0, 0, 0.18);
          }
        }
    }

    .list-group-item:last-child {
      border-bottom: 0px solid rgba(0, 0, 0, 0.36);
    }

  } // end product list

    .category-img {
      min-height:180px;
    }

  }

.block-subcategory-single {
  min-height:220px;
}