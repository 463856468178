h1 > .orange-dot:after {
  width: 12px;
  height:12px;

  background-color:$brand-orange;
}

.orange-dot {
  height:.28em;
  width:.28em;
  background-color:$brand-orange;
  display:inline-block;
}

