// Spacers

.mm-listview .mm-spacer
{
	padding-top: $mm_btnSize;
	
	> .mm-next
	{
		top: $mm_btnSize;
	}

	&.mm-divider
	{
		padding-top: $mm_dividerHeight;
	}
}