@import "../bootstrap/_variables.scss";


// icons
$icon-font-path: "../fonts/";
$icon-font-name: "toolkit-entypo";


// Gray colors/* I was always somebod: */
$body-color:   rgba(0,0,0,0.60);
$gray-base:    #000;
$gray-darker:  lighten($gray-base, 13.5%); // #222
$gray-dark:    lighten($gray-base, 20%);   // #333
$gray:         lighten($gray-base, 33.5%); // #555
$gray-light:   lighten($gray-base, 66.7%); // #777
$gray-lighter: lighten($gray-base, 93.5%); // #eee


// Brand Colors

$brand-primary: #F48120;

$brand-orange: #F48120;
$brand-orange-dark: #E9730F;
$brand-orange-extra-dark: #C36B16;

$brand-success: #5cb85c;
$brand-info:    #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger:  #FF3167;


// text
$text-color: rgba(0,0,0,0.60);
$letter-spacing-base: 0em;


// Spacing
$spacer:            20px;
$spacer-x:          $spacer;
$spacer-y:          $spacer;
$grid-gutter-width: 20px;

// Typography
$font-size-root: 1.1em;
$font-family-sans-serif:  "Titillium Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       "Lora", Georgia, "Times New Roman", Times, serif;
$font-family-base:        $font-family-sans-serif;
$font-weight-base:        400;
$font-weight-bold:        600;
$line-height-base:        1.6;


// For h1-h6
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 700;
$headings-line-height: 1.2;
$headings-small-color: inherit;
$headings-color:  #000;

$font-size-h1: 1.85rem;
$font-size-h2: 1.625rem !default;
$font-size-h3: 1.375rem !default;
$font-size-h4: 1.125rem !default;
$font-size-h5: 1.1rem !default;
$font-size-h6: 1rem !default;


// For .lead
$lead-font-size:      ($font-size-base * 1.15);
$lead-font-size-sm:   ($font-size-base * 1.25);
$lead-font-weight:    300;
$lead-line-height:    1.6;
$lead-letter-spacing: 0;



// Buttons
$btn-font-size:      1.2em;
$btn-font-weight:    600;
$btn-text-transform: none;
$btn-letter-spacing: normal;
$btn-padding-x-xs:   0.063rem !default; // TODO: Change this to REMs since most of v4 in REMS
$btn-padding-y-xs:   0.313rem !default; // TODO: Change this to REMs since most of v4 in REMS

$btn-toolbar-divider-bg: $gray-lighter;

$btn-primary-bg:     $brand-orange;
$btn-primary-border: $brand-orange-dark;
$link-color:         $brand-orange;
$link-hover-color:   #000;

$btn-border-radius: 8px;

// Forms
$input-border-radius: 8px;

// List group
$list-group-border-radius: 0;
$list-group-item-padding-y: 0.55em;
$list-group-item-padding-x: 1.25em;


// Container sizes
$container-desktop:       (860px + $grid-gutter-width);
$container-md:            $container-desktop;
$container-large-desktop: (1000px + $grid-gutter-width);
$container-lg:            $container-large-desktop;

// nav header
$nav-border-color: rgba(255,255,255,0.18) !default;


// Grid containers
$container-max-widths: (
  md: 700px + $grid-gutter-width-base,
  lg: 900px + $grid-gutter-width-base,
  xl: 1100px + $grid-gutter-width-base
);
@include _assert-ascending($container-max-widths, "$container-max-widths");



// Block
$block-color:              inherit;
$block-bg:                 #fff;
$block-color-inverted:     rgba(0,0,0,0.60);
$block-bg-inverted:        rgba(239,239,239,0.32);
$block-title-font-size-sm: $font-size-base * 4;
$block-title-font-size:    $font-size-base * 3;
$block-lead-font-size-sm:  $font-size-base * 1.25;
$block-lead-font-size:     $font-size-base * 1.15;
$block-vertical-padding:   60px;

// Hamburger menu

$hamburger-layer-width: 36px;
$hamburger-layer-height: 3px;