.block-service-suggestion {


  .service-suggestion-bg {
    height: 188px;
  }

  .hero-block-list {
    margin-top:-48px;
  }

  @include media-breakpoint-down(sm) {
    .hero-block-list {

      margin-top:1.5rem;
    }
  }

  .list-group-title {
    line-height: 1.875rem;
    //border-top-left-radius:8px;
    //border-top-right-radius:8px;
  }

  .list-group {


    //border-left:1px solid rgba(0,0,0,0.36);
    //border-right:1px solid rgba(0,0,0,0.36);
    //border-bottom:1px solid rgba(0,0,0,.36);

    border: 0px solid rgba(0,0,0,.08);
    box-shadow: 0 16px 38px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);

    //border-top-left-radius:8px;
    //border-top-right-radius:8px;

    border-radius: 0px;

    background-color:#fff;


    p,
    ul
    {
      @extend .pt-3;
      @extend .px-3;
      @extend .mb-0;
    }

    ul {
      @extend .p-3;
    }


  }

}