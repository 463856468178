footer {
  background:#000;
  font-size: 0.840em;
  color: rgba(255,255,255,0.38);


  h4 {
         font-size: 1em;
         color: $white;
       }


  a {

    font-size: 0.875em;
    color: rgba(255,255,255,0.38);

    &:hover, &:focus {
      color:$white;
      background:none;
    }
  }

  p {

    font-size: 0.840em;
    color: rgba(255,255,255,0.38);
  }

  .socialmedia,
  .newsletter,
  .phone  {
    //border-bottom: 1px solid $nav-border-color;
    color:$white;
    line-height:36px;

    h6 {

      font-size: .94em;
      color: #fff;
      font-weight: $font-weight-bold;


    }
    a,a:link {
      color:$white;
      font-weight: $font-weight-bold;
      vertical-align: middle;

      &:hover, &:focus {
        color:$white;
        background:none;
      }

    }
  }
.phone {


  font-size: 1.250em;
  color: #fff;
  font-weight: $font-weight-bold;


}
 form {
    .input-group {

      input,
      button {

        background:#000;
        border: 1px solid rgba(255,255,255,0.50);
        color: rgba(255,255,255,0.38);
      }

      button .btn-default {
        color: rgba(255,255,255,0.38);
        width:120px;
      }

    }
 }



  .newsletter {
    border-bottom:0px solid #fff;
  }

  .footer-column:first-child {
    border-left: 1px solid $nav-border-color;

  }

  .footer-column {
      border-right: 1px solid $nav-border-color;
      border-left: 1px solid $nav-border-color;
  }

  .footer-nav-columns {
    border-top: 1px solid $nav-border-color;
    border-bottom: 1px solid $nav-border-color;

  }

  .footer-bottom-nav {
    line-height:36px;
    border-left: 1px solid $nav-border-color;
    border-right: 1px solid $nav-border-color;
    border-bottom: 1px solid $nav-border-color;
  }

  .copyright {

  }

}

