
//// GRAVITY FORMS -> BOOTSTRAP 4
//

label {
  margin-bottom: .2rem;
  font-weight:bold;
}

.gform_fields {
  @extend .list-unstyled;
  //@extend .row;

  input, select, textarea {
    @extend .form-control;
  }

  textarea {
    height: auto;
  }

}

.gfield_description {
    font-weight:normal;
  font-size: .8em;
  margin:6px 0;
}

.validation_error {
  margin-bottom: .5rem;
  color: $alert-danger-text;
  @extend .form-control-feedback;
  @extend .col-12;
}

.validation_message {
  @extend .text-center;
  @extend .form-control-feedback;
}

.gform_button {
  @extend .btn;
}

.gfield_required {
  color: $alert-danger-text;
}

.gform_wrapper ul.gfield_radio li, .gform_wrapper ul.gfield_checkbox li {
  @extend .form-check;
}

.gform_validation_container {
  display: none;
}



.gform_validation_error .gform_fields {
  //@extend .has-error;
}

.gform_validation_error .gfield_error {
  @extend .has-danger;
}

.gform_validation_error .ginput_container input {
  //@extend .form-control-success;

}

.gform_validation_error .gfield_error .ginput_container input {
  @extend .form-control-danger;

}

#gform_1 li,
#gform_5 li,
#gform_2 li {
  display:block;

  @extend .col-md-8;
  @extend .col-sm-10;
  @extend .col-12;
  @extend .mb-2;
  @extend .pl-0;
  @extend .ml-0;

}


#gform_1 > .gform_footer,
#gform_5 > .gform_footer,
#gform_2 > .gform_footer {
  display: inline-block;
  padding: 0;

  @extend .mb-4;


  .button {
    @extend .btn-primary;
  }
}







#gform_1 .validation_message,
#gform_5 .validation_message,
#gform_2 .validation_message {
  @extend .text-left;
  @extend .form-control-feedback;
}



//#gform_1 li {
//
//  background-color:transparent;
//  border:0px solid #fff;
//  padding:0px 1px;
//
//
//  .gfield_label {
//    display: none;
//  }
//}

//
//#gforms_confirmation_message_1 {
//
//  @extend .col-md-12;
//  @extend .text-center;
//  @extend .lead;
//  @extend .has-success;
//}


// Forms with multiple pages

.gf_page_steps {
  @extend .nav;
  @extend .nav-tabs;
  @extend .mb-3;
  @extend .pl-3;
  @extend .pr-3;
  @extend .pt-3;
  @extend .pt-0;

  @extend .rounded-top;
  @extend .block-inverse;

}

.gf_step {
  @extend .nav-link;
  @extend .nav-item;
  @extend .pl-1;
  @extend .pr-1;
  @extend .pr-sm-3;
  @extend .pl-sm-3;

  @extend .align-middle;
  opacity: .8;



}

.gf_step_active {
  @extend .active;
  color:$brand-primary;
  font-weight: $font-weight-bold;
  opacity: 1;

}
//
//.gf_step_completed {
//
// background-color:$block-bg-inverted;
//  border-bottom: 1px solid #ddd;
//
//
//  .gf_step_number:before {
//    content: $fa-var-check; /* FontAwesome Unicode */
//    font-size:1.4em;
//    font-family: FontAwesome;
//    color:#61bf23;
//  }
//
//}


#gform_4 {
  input,
  textarea {
    @extend .col-12;
    @extend .col-sm-8;
    @extend .col-md-6;
  }

  label {
    margin-top: .4rem;
    margin-bottom: .2rem;
  }


  #gform_submit_button_4 {
    @extend .btn;
    @extend .btn-primary;

    @extend .col-8;
    @extend .col-sm-6;
    @extend .col-md-3;
  }


}


#gform_wrapper_3,
#gform_wrapper_5,
#gform_wrapper_8 {

  .gform_body {

    @extend .pt-3;
    @extend .pl-4;
    @extend .pr-4;
  }

  input[type="email"],
  input[type="text"],
  input,
  textarea {
    @extend .col-12;
    @extend .col-sm-12;
    @extend .col-md-6;
    @extend .col-lg-6;
  }

  input[type=checkbox] {
    width:20px;
    margin-top:8px;
    margin-right:8px;

  }

  @include media-breakpoint-only(xs) {
    input[type=checkbox] {
      width:20px;
      margin-top:4px;
      margin-right:0;

    }
  }
  .ginput_container_checkbox {

    @extend .ml-0;
    @extend .pl-0;

    ul {
      @extend .ml-0;
      @extend .pl-0;
      @extend .mb-2;
    }

    ul > li {
      @extend .col-12;
      min-height:1.4rem;

      @extend .m-0;
      @extend .p-0;

    }
    ul > li > input[type="checkbox"] {
      //width:26px;
      //height:26px;
      float:left;

    }

    ul > li > label {
      float:left;
          margin:0;
    }

  }



  .validation_message {
    @extend .text-left;
  }

  ul.gform_fields {

    li {

      @extend .mt-2;
    }
  }

  #gform_submit_button_2,
  #gform_submit_button_5,
  #gform_submit_button_8,
  #gform_submit_button_3,
  #gform_submit_button_7,
  #gform_submit_button_6 {
    @extend .btn;
    @extend .btn-primary;

    @extend .col-12;
    @extend .col-sm-6;
    @extend .col-md-4;
    @extend .mb-2;
  }



  #gform_previous_button_5,
  #gform_previous_button_8,
  #gform_previous_button_4,
  #gform_previous_button_3,
  #gform_next_button_3_2,
  #gform_next_button_5_2,
  #gform_next_button_8_2 {
    @extend .btn;
    @extend .btn-outline-primary;
    @extend .text-center;


    @extend .col-8;
    @extend .col-sm-4;
    @extend .col-md-4;
    @extend .mb-2;


  }


  .gform_page_footer {
    @extend .mb-4;
  }
}

//img.gform_ajax_spinner {
//  position: fixed !important;
//  z-index: 999999;
//  left: 0;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  display: block !important;
//  overflow: hidden  !important;
//  width: 100% !important;
//  height: 100% !important;
//  background-color:transparent; /* fall back */
//  background-image: url('../images/loading.gif');  /* path to your new spinner */
//  background-repeat: no-repeat;
//  background-size: 60px 60px;
//  background-position: center center;
//}

#gform_submit_button_2,
#gform_submit_button_5,
#gform_submit_button_8,
#gform_submit_button_3,
#gform_submit_button_7,
#gform_submit_button_6{
  @extend .btn;
  @extend .btn-primary;

  @extend .col-12;
  @extend .col-sm-6;
  @extend .col-md-4;
  @extend .mb-2;
}
