.block-hero {


 .hero-bg {
    //min-height: 360px;


    h2 {
      color: $white;
      z-index:10;
    }


  }

  .hero-block-list {
    //position:relative;
    //z-index:10;
    //margin-top:-48px;
  }

}

