.block-support {
  background: #fff;
  border-bottom:1px solid rgba(0,0,0,0.08);


  //box-shadow: 0 16px 38px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);

  //box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  //
  //border-radius:8px;


  h3,
  p {
    display:block;
   clear:both;
  }


  h3 {
    font-size: 1.2em;
  }


  .support-img {
    margin-top:-32px;

  }

 .btn-primary-shadow {
   box-shadow: 0 2px 2px 0 rgba(255,152,0,.14), 0 3px 1px -2px rgba(255,152,0,.2), 0 1px 5px 0 rgba(255,152,0,.12);
   border-radius:8px;

   &:hover, &:focus {
     box-shadow: 0 14px 26px -12px rgba(255,152,0,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(255,152,0,.2)
   }
 }

  .support-links {
    //border-top: 1px solid rgba(0,0,0,.08);
  }

}


.block-support-mobile {

  border:1px solid rgba(0,0,0,0.08);
  box-shadow: 0 16px 38px -12px rgba(0,0,0,.56), 0 4px 25px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  border-radius:8px;

  .support-links {
    border-top: 1px solid rgba(0,0,0,.08);
  }

}



@include media-breakpoint-down(sm) {
  .block-support {
    background: none;
    border-bottom: none;

    h3 {
      font-size: 1.2em;
    }

    p.lead {
      font-size: 1rem;
    }

    .support-img {
      border-bottom:1px solid rgba(0,0,0,0.08);
    }

    .support-text {
    }

    .btn-primary-shadow {
      box-shadow: 0 2px 2px 0 rgba(255,152,0,.14), 0 3px 1px -2px rgba(255,152,0,.2), 0 1px 5px 0 rgba(255,152,0,.12);
      border-radius:8px;

      &:hover, &:focus {
        box-shadow: 0 14px 26px -12px rgba(255,152,0,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(255,152,0,.2)
      }
    }

  }
}