.block-services-titles {

  .overview-item-block {

    background: $white;

    border-right: 1px solid rgba(116, 119, 121, 0.20);
    border-bottom: 1px solid rgba(116, 119, 121, 0.20);

  }


    .overview-item-block:nth-child(even) {
      border-right: 0px solid rgba(116, 119, 121, 0.20);
      //background:red;
    }


}