#top-nav,
#top-nav-mobile {
  background:#000;
  border-bottom: 1px solid #fff;


}


.nav-box,
.brand-box {

  border-right: 1px solid $nav-border-color;
}


.header-nav {

   li.menu-item {

     @extend .p-2;
     @extend .nav-item;

     a {
       @extend .nav-link;

       padding: 0.2em 0.2em;

       &:hover, &:focus {
         color:$white;
       }
     }


   }

  li.menu-item.active {
    background:$white;
    color:$brand-orange;

    a {
      @extend .nav-link;

      padding: 0.2em 0.2em;

      &:hover, &:focus {
        color:$brand-orange;
      }
    }

  }


}


/*Header mmenu */
.mm-navbar {
  height:66px;
  color:#fff;
}

.mm-listview {
  background-color:#000;
}


.mm-listview>li a {

  font-family: $font-family-sans-serif;
  color: #fff;
  text-indent:20px;
}

.mm-listview>li a:hover,
.mm-listview>li:hover {

  display:block;
  background: #303030;
  text-indent:20px;


}


.mm-vertical .sub-menu {


 li a{
  font-family: $font-family-sans-serif;
  color: #fff;

  background: url("../images/icon-btn-arrow-right.svg") 20px center no-repeat;
  text-indent:40px;

}

li.active a,
li.mm-selected a{
  background-color:#151515;
  color:#fff;
  background: url("../images/icon-btn-arrow-right.svg") 20px center no-repeat;
  text-indent:40px;
}

li:hover a{

  color:#fff;
  background:  url("../images/icon-btn-arrow-right-white.svg") 20px center no-repeat;
  text-indent: 40px;


}

}

#header-menu:not( .mm-menu ) {
  display: none;
}


.mm-listview .mm-vertical .mm-panel,
.mm-vertical .mm-listview .mm-panel {
  display:block !important;
}




.mm-next { display:none !important; }

.mm-panels>.mm-panel {
  margin-top:10px;
}

.header-mobile-phone {
  border-bottom: 1px solid #fff;
  height:69px;

  a,
  a:link {

    color:#fff !important;
    line-height:69px;

    font-size: 1rem;
    font-weight: 600;
  }

}


.mm-vertical .sub-menu {

  background: #141414;

}


.mm-listview .mm-next+a, .mm-listview .mm-next+span,
.mm-listview .mm-next+a, .mm-listview .mm-next+span {
  margin-right:0;

}

.mm-panels>.mm-panel>.mm-listview:first-child, .mm-panels>.mm-panel>.mm-navbar+.mm-listview {
  margin-top: 68px;
}

.mm-navbar>* {
  padding:0;
}