.block-client-tiles {

  .client-intro-line {
    //min-height: 304px;
    background: rgba(239,239,239,0.50);

    border-top: 1px solid rgba(0, 0, 0, 0.18);
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  }

  .client-column {
    background:$white;

    border:1px solid rgba(239,239,239,0.50);

    .client-impression {
      position: relative;


      .client-logo {
        position: absolute;
        right: 0;
        bottom: 28px;

        background: $white;

      }
    }
  }

  .client-tiles-bg {

    border-bottom: 1px solid rgba(0, 0, 0, 0.18);

  }
  .client-list {

    .list-group {

      border-left: 1px solid rgba(0, 0, 0, 0.36);
      border-right: 1px solid rgba(0, 0, 0, 0.36);
      border-bottom: 1px solid rgba(0, 0, 0, 0.36);

    }

    .btn-default {
      border-bottom: 1px solid rgba(0, 0, 0, 0.18);
      border-left: 0px;
      border-right: 0px solid rgba(0, 0, 0, 0.36);
      border-top: 0px solid rgba(0, 0, 0, 0.36);
    }

    .btn-default:last-child {
      border-bottom: 0px solid rgba(0, 0, 0, 0.36);
    }

  }

  .client-img {
    min-height:180px;
  }

}




.list-group-title {
  @extend h4;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 0.55rem 1.25rem;
  margin-bottom: -1px;

  background: rgba(0, 0, 0,1);
  border-radius: 0;
  color: $white;
}

