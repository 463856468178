.block-services-titles-image {

  .overview-item-block {

    background: $white;

    border-right: 1px solid rgba(116, 119, 121, 0.20);
    border-bottom: 1px solid rgba(116, 119, 121, 0.20);

  }
  @include media-breakpoint-up(md) {

    .overview-item-block:nth-child(3) {
      border-right: 0px solid rgba(116, 119, 121, 0.20);
      //background:red;
    }
  }

  @include media-breakpoint-down(sm) {
    .overview-item-block:nth-child(even) {
      border-right: 0px solid rgba(116, 119, 121, 0.20);
      //background:blue;
    }

  }




}