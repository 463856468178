/*------------------------------------*\
    #HEADINGS
\*------------------------------------*/

/**
 * Heading 1
 */

h1 {
	font-size: 1.85rem;
	line-height: 1.125;
}

@include media-breakpoint-up(sm) {
	h1 {
		font-size: 1.85rem;
		line-height: 1.125;
	}
}

@include media-breakpoint-up(md) {
	h1 {
		font-size: 2rem;
		line-height: 1.125;
	}
}

@include media-breakpoint-up(lg) {
	h1 {
		font-size: 2rem;
		line-height: 1.05;
	}
}

/**
 * Heading 2
 */

h2 {
	font-size: 1.625rem;
	/* 1.625x body copy size = 26px */
	line-height: 1.15384615;
	/* 30px / 26px */
	color:rgba(0,0,0,0.75);
}

@include media-breakpoint-up(sm) {
	h2 {
		font-size: 1.625rem;
		/* 1.625x body copy size = 26px */
		line-height: 1.15384615;
		/* 30px / 26px */
	}
}

@include media-breakpoint-up(md) {
	h2 {
		font-size: 1.85rem;
		/* 2x body copy size = 32px */
		line-height: 1.25;
	}
}

@include media-breakpoint-up(lg) {
	h2 {
		font-size: 1.85rem;
		/* 2.25x body copy size = 36px */
		line-height: 1.25;
	}
}

/**
 * Heading 3
 */

h3 {
	font-size: 1.375rem;
	/* 1.375x body copy size = 22px */
	line-height: 1.13636364;
	/* 25px / 22px */
}

@include media-breakpoint-up(sm) {
	h3 {
		font-size: 1.5rem;
		/* 1.5x body copy size = 24px */
		line-height: 1.25;
	}
}

@include media-breakpoint-up(lg) {
	h3 {
		font-size: 1.65rem;
		/* 1.75x body copy size = 28px */
		line-height: 1.25;
	}
}

/**
 * Heading 4
 */
h4 {
	font-size: 1.125rem;
	/* 1.125x body copy size = 18px */
	line-height: 1.11111111;
}

@include media-breakpoint-up(sm) {
	h4 {
		line-height: 1.22222222;
		/* (22px / 18px */
	}
}


